// import { useEffect, useState } from 'react'
// import Comp1 from './components/comp1'
// import Comp2 from './components/comp2'
// import Comp3 from './components/comp3'
// import Comp4 from './components/comp4'
// import Comp5 from './components/comp5'
// import Comp6 from './components/comp6'
// import About from './components/about'

import './App.css';

function App() {

  // const [showAbout, setShowAbout] = useState(false)
  // const [bottomReached, setBottomReached] = useState(0)

  // const comps = [ <Comp1 />, <Comp2 />, <Comp3 />, <Comp4 />, <Comp5 />, <Comp6 />];
  // const addedComps = []

  // for (let i = 0; i < bottomReached; i++) {
  //     addedComps.push(comps)
  // }

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, []);

  // const handleScroll = () => {
  //   if (window.innerHeight * 2 + window.scrollY >= document.body.offsetHeight) {
  //     setBottomReached((bottomReached) => bottomReached + 1)
  //   }
  // };

  // return (
  //   <div className="App">
  //     <h2 className="header" onClick={() => setShowAbout(true)}>Rick Erfmann</h2>
  //     <About show={showAbout} onClose={() => setShowAbout(false)}/>
  //     {comps}
  //     {addedComps}
  //   </div>
  // );


  return (
    <div className="App"
    style={{display: "flex", flexDirection: "column", gap: "12px", justifyContent: "center", alignItems: "center", textAlign: "center"}}
    >
      <div>under construction</div>

      <div>
        <a href="mailto:contact@rickerfmann.com"
        style={{color: "black", display: "inline"}}
        >
          contact@rickerfmann.com
        </a>
      </div>

      <div>+31633683583</div>

    </div>
  )
}

export default App;
